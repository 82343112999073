<template>
	<div>
		<section class="section intro">
			<div class="level">
				<div class="level-left">
					<h2
						v-if="user"
						class="title has-text-centered-mobile is-size-5-mobile"
					>
						{{ $t("Hello") }}
					</h2>
				</div>				
			</div>
		</section>		
		<b-loading v-model="isLoading"></b-loading>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "Home",
	components: {},
	computed: {
		...mapGetters({
			user: "GET_USER",
			selectedCityModule: "GET_CITY_MODULE",
		}),
	},
	data() {
		return {
			projects: null,
			isLoading: false,
			latestProjectsCount: 8,
		};
	},
	mounted() {
		document.title = this.$t("Welcome") + " - GIS ANCPI";
		document.body.classList.add("home");
		this.$scrollToTop();
	},
	watch: {
		"$i18n.locale"() {
			document.title = this.$t("Welcome") + " - GIS ANCPI";
		},
		selectedCityModule() {
			this.getProjects();
		},
	},
	methods: {

	},
	destroyed() {
		document.body.classList.remove("home");
	},
};
</script>
